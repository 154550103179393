.radix-themes {
  --container-1: 375px;
  --container-3: 768px;
  --container-4: 1248px;
  --cursor-button: pointer;
  --default-font-family: 'DM Sans', serif;
  --base-card-surface-box-shadow: 0 0 0 1px var(--gray-4);

  /* semantic colors start */
  --error-1: var(--red-1);
  --error-2: var(--red-2);
  --error-3: var(--red-3);
  --error-4: var(--red-4);
  --error-5: var(--red-5);
  --error-6: var(--red-6);
  --error-7: var(--red-7);
  --error-8: var(--red-8);
  --error-9: var(--red-9);
  --error-10: var(--red-10);
  --error-11: var(--red-11);
  --error-12: var(--red-12);

  --error-a1: var(--red-a1);
  --error-a2: var(--red-a2);
  --error-a3: var(--red-a3);
  --error-a4: var(--red-a4);
  --error-a5: var(--red-a5);
  --error-a6: var(--red-a6);
  --error-a7: var(--red-a7);
  --error-a8: var(--red-a8);
  --error-a9: var(--red-a9);
  --error-a10: var(--red-a10);
  --error-a11: var(--red-a11);
  --error-a12: var(--red-a12);

  --error-surface: var(--red-surface);
  --error-indicator: var(--red-indicator);
  --error-track: var(--red-track);
  --error-contrast: var(--red-contrast);

  --info-1: var(--blue-1);
  --info-2: var(--blue-2);
  --info-3: var(--blue-3);
  --info-4: var(--blue-4);
  --info-5: var(--blue-5);
  --info-6: var(--blue-6);
  --info-7: var(--blue-7);
  --info-8: var(--blue-8);
  --info-9: var(--blue-9);
  --info-10: var(--blue-10);
  --info-11: var(--blue-11);
  --info-12: var(--blue-12);

  --info-a1: var(--blue-a1);
  --info-a2: var(--blue-a2);
  --info-a3: var(--blue-a3);
  --info-a4: var(--blue-a4);
  --info-a5: var(--blue-a5);
  --info-a6: var(--blue-a6);
  --info-a7: var(--blue-a7);
  --info-a8: var(--blue-a8);
  --info-a9: var(--blue-a9);
  --info-a10: var(--blue-a10);
  --info-a11: var(--blue-a11);
  --info-a12: var(--blue-a12);

  --info-surface: var(--blue-surface);
  --info-indicator: var(--blue-indicator);
  --info-track: var(--blue-track);
  --info-contrast: var(--blue-contrast);

  --success-1: var(--green-1);
  --success-2: var(--green-2);
  --success-3: var(--green-3);
  --success-4: var(--green-4);
  --success-5: var(--green-5);
  --success-6: var(--green-6);
  --success-7: var(--green-7);
  --success-8: var(--green-8);
  --success-9: var(--green-9);
  --success-10: var(--green-10);
  --success-11: var(--green-11);
  --success-12: var(--green-12);

  --success-a1: var(--green-a1);
  --success-a2: var(--green-a2);
  --success-a3: var(--green-a3);
  --success-a4: var(--green-a4);
  --success-a5: var(--green-a5);
  --success-a6: var(--green-a6);
  --success-a7: var(--green-a7);
  --success-a8: var(--green-a8);
  --success-a9: var(--green-a9);
  --success-a10: var(--green-a10);
  --success-a11: var(--green-a11);
  --success-a12: var(--green-a12);

  --success-surface: var(--green-surface);
  --success-indicator: var(--green-indicator);
  --success-track: var(--green-track);
  --success-contrast: var(--green-contrast);

  --warning-1: var(--yellow-1);
  --warning-2: var(--yellow-2);
  --warning-3: var(--yellow-3);
  --warning-4: var(--yellow-4);
  --warning-5: var(--yellow-5);
  --warning-6: var(--yellow-6);
  --warning-7: var(--yellow-7);
  --warning-8: var(--yellow-8);
  --warning-9: var(--yellow-9);
  --warning-10: var(--yellow-10);
  --warning-11: var(--yellow-11);
  --warning-12: var(--yellow-12);

  --warning-a1: var(--yellow-a1);
  --warning-a2: var(--yellow-a2);
  --warning-a3: var(--yellow-a3);
  --warning-a4: var(--yellow-a4);
  --warning-a5: var(--yellow-a5);
  --warning-a6: var(--yellow-a6);
  --warning-a7: var(--yellow-a7);
  --warning-a8: var(--yellow-a8);
  --warning-a9: var(--yellow-a9);
  --warning-a10: var(--yellow-a10);
  --warning-a11: var(--yellow-a11);
  --warning-a12: var(--yellow-a12);

  --warning-surface: var(--yellow-surface);
  --warning-indicator: var(--yellow-indicator);
  --warning-track: var(--yellow-track);
  --warning-contrast: var(--yellow-contrast);

  --neutral-1: var(--gray-1);
  --neutral-2: var(--gray-2);
  --neutral-3: var(--gray-3);
  --neutral-4: var(--gray-4);
  --neutral-5: var(--gray-5);
  --neutral-6: var(--gray-6);
  --neutral-7: var(--gray-7);
  --neutral-8: var(--gray-8);
  --neutral-9: var(--gray-9);
  --neutral-10: var(--gray-10);
  --neutral-11: var(--gray-11);
  --neutral-12: var(--gray-12);

  --neutral-a1: var(--gray-a1);
  --neutral-a2: var(--gray-a2);
  --neutral-a3: var(--gray-a3);
  --neutral-a4: var(--gray-a4);
  --neutral-a5: var(--gray-a5);
  --neutral-a6: var(--gray-a6);
  --neutral-a7: var(--gray-a7);
  --neutral-a8: var(--gray-a8);
  --neutral-a9: var(--gray-a9);
  --neutral-a10: var(--gray-a10);
  --neutral-a11: var(--gray-a11);
  --neutral-a12: var(--gray-a12);

  --neutral-surface: var(--gray-surface);
  --neutral-indicator: var(--gray-indicator);
  --neutral-track: var(--gray-track);
  --neutral-contrast: var(--gray-contrast);
  /* semantic colors end */
}

.rt-DropdownMenuSeparator {
  margin-left: 0;
  margin-right: 0;
}

.rt-TableBody {
  tr:last-child {
    td,
    th {
      box-shadow: none;
    }
  }
}

.rt-Separator {
  background-color: var(--gray-a4);
}

/* TODO: remove this hack for flickering Dialog, related issue https://github.com/radix-ui/primitives/issues/3286#issuecomment-2561493444 */
.rt-BaseDialogOverlay {
  &:where([data-state='closed']) {
    animation: rt-dialog-overlay-no-op 200ms cubic-bezier(0.16, 1, 0.3, 1) !important;
  }
  &:where([data-state='open'])::before {
    animation: rt-fade-in 200ms cubic-bezier(0.16, 1, 0.3, 1) !important;
  }
  &:where([data-state='closed'])::before {
    opacity: 0;
    animation: rt-fade-out 200ms cubic-bezier(0.16, 1, 0.3, 1) !important;
  }
}

.rt-BaseDialogContent {
  &:where([data-state='open']) {
    animation: rt-dialog-content-show 200ms cubic-bezier(0.16, 1, 0.3, 1) !important;
  }
  &:where([data-state='closed']) {
    opacity: 0;
    animation: rt-dialog-content-hide 100ms cubic-bezier(0.16, 1, 0.3, 1) !important;
  }
}

.rt-SegmentedControlItemLabelActive,
.rt-SegmentedControlItemLabelInactive,
.rt-Button {
  font-weight: var(--font-weight-bold);
}
